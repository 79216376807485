$(function () {
    $('form[data-id="send-form"] button[type=submit]').click(function () {
        $("button[type=submit]", $(this).parents("form")).removeAttr("clicked");
        $(this).attr("clicked", "true");
    });

    $('form[data-id="send-form"]').on('submit', function (event) {
        event.preventDefault();

        let form = $(this);
        let formData = new FormData(this);

        let errorAlert = $(this).attr('data-error-alert');
        let action = form.attr('action');
        let btnClick = form.find("button[type=submit][clicked=true]");
        let btnName = btnClick.text();

        formData.append('action', btnClick.attr('value'));

        $.ajax({
            url: action,
            method: "POST",
            data: formData,
            dataType: 'JSON',
            contentType: false,
            cache: false,
            processData: false,
            beforeSend: function () {
                $('.content').find('div[role="alert"]').remove();
                form.find('input').removeClass('is-invalid');
                form.find('span.invalid-feedback').remove();

                form.find("button[type=submit]").prop('disabled', true);
                btnClick.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...');
            }
        }).done(function (data) {
            location.replace(data.redirect);
        }).fail(function (jqXHR) {
            if (jqXHR.status === 422) {
                let errors = jqXHR.responseJSON.errors;

                $.each(errors, function (key, value) {
                    key = key.replaceAll('.', '_');

                    let input = form.find('[id="' + key + '"]');

                    input.addClass('is-invalid');
                    input.parent().append('<span class="invalid-feedback" role="alert">' + value[0] + '</span>');
                });

                if (errorAlert !== 'false') {
                    showErrorAlert('There are some validation errors');
                }
            } else {
                alert('Error. Please try again');
            }
        }).always(function () {
            btnClick.text(btnName);
            form.find("button[type=submit]").prop('disabled', false);
        });
    });

    function showErrorAlert(message) {
        let alert = '<div class="alert alert-danger alert-dismissible fade show" role="alert">\n' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">\n' +
            '<span aria-hidden="true">&times;</span>\n' +
            '</button>\n' +
            '' + message + '\n' +
            '</div>';
        $('.content').find('.container-fluid').prepend(alert);
    }
});
